import React from 'react'
import { useToast, ButtonGroup } from '@veneer/core'
import { useI18n } from '@jarvis/react-portal-addons'

import * as Wrapper from './styles'

// Footer component
const Footer = (props) => {
  const { setOpen, onDelete } = props
  const toast = useToast()
  const { addToast } = toast
  const { t } = useI18n()

  return (
    <div
      data-testid="reports_DeleteModal_Action_Buttons"
      style={{
        marginLeft: 'auto',
        display: 'block'
      }}
    >
      <ButtonGroup>
        {/* Cancel button */}
        <Wrapper.DeleteButton
          data-testid="reports_DeleteModal_Cancel_Buttons"
          id="reports_DeleteModal_Cancel_Buttons"
          appearance="secondary"
          onClick={() => {
            setOpen(false)
          }}
        >
          {t('ecp-global-reports.button.cancel', 'Cancel')}
        </Wrapper.DeleteButton>
        {/* Delete button */}
        <Wrapper.DeleteButton
          data-testid="reports_DeleteModal_Delete_Buttons"
          id="reports_DeleteModal_Delete_Buttons"
          appearance="danger"
          onClick={() => {
            setOpen(false)
            // Show informative toast notification
            addToast({
              id: 'Multi-deletePolicyToast-inProgress',
              type: 'informative',
              text: t(
                'ecp-global-reports.toastNotification.deletionInprogresstext',
                'A report is deleting, please wait for notification ...'
              ),
              timeout: 3
            })
            onDelete()
          }}
        >
          {t('ecp-global-reports.button.delete', 'Delete')}
        </Wrapper.DeleteButton>
      </ButtonGroup>
    </div>
  )
}

export default Footer
