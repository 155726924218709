import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import {
  reportsListColumns,
  sessionStorageKeys,
  reportTableOrder,
  reportPageSize,
  Tablei18nText,
  TablePaginationText
} from '../../utils/consts'

import { ContextualFooter } from '../ContextualFooter'
import { ReportListSubHeader } from '../ReportListSubHeader'
import { DeleteModal } from '../Modals/DeleteModal'

import * as Wrapper from './styles'
import { useReports } from '../../hooks/useReports'
import GeneralContext from '../../contexts/GeneralContext'
import {
  Button,
  IconWarningAlt,
  ThemeProvider,
  useToast,
  Table,
  SortTypes
} from '@veneer/core'
import { ScopeContext } from '../../scopes/ScopeContext'
// import { listSort } from '../ReportDetails/Common/ReportUtils'

export const ReportListTable = (props) => {
  const {
    unFilteredData,
    setUnFilteredData,
    tableData,
    setTableData,
    tableDataChecked,
    setTableDataChecked,
    loading,
    reportResponse,
    setLoading,
    searchText,
    setSearchText,
    errorFetching,
    handleReloadPage,
    handleSearch,
    disableSearchFilter,
    reloadReportList
  } = props
  const { READ_SCOPE, WRITE_SCOPE, DELETE_SCOPE } = useContext(ScopeContext)
  const { stack } = useContext(GeneralContext)
  const { t } = useI18n()
  const { deleteReports } = useReports()
  const { addToast } = useToast()

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [orderType, setOrderType] = useState('descending')
  const [orderBy, setOrderBy] = useState('generatedTime')
  const [sortEnable, setSortEnable] = useState<any>(true)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [numberOfSelectedItems, setNumberOfSelectedItems] = useState([])

  const [pageSize, setPageSize] = useState(
    JSON.parse(sessionStorage.getItem(sessionStorageKeys.reportsPageSize)) ??
      reportPageSize
  )
  const [order, setOrder] = useState(
    JSON.parse(sessionStorage.getItem(sessionStorageKeys.reportsListColumns)) ??
      reportTableOrder
  )

  const descendingComparator = (a, b, orderBy) => {
    var aSort = new Date(a.timeStamp).getTime()
    var bSort = new Date(b.timeStamp).getTime()

    if (bSort > aSort) {
      return -1
    }
    if (bSort < aSort) {
      return 1
    }
    return 0
  }

  const sort = (array, orderBy, orderType) => {
    const list = array.sort((a, b) => {
      const value =
        orderType === 'ascending'
          ? descendingComparator(a, b, orderBy)
          : -descendingComparator(a, b, orderBy)

      return value
    })
    return list.slice(
      (currentPage - 1) * pageSize,
      (currentPage - 1) * pageSize + pageSize
    )
  }
  const pagedData = () =>
    [...tableData].slice(
      (currentPage - 1) * pageSize,
      (currentPage - 1) * pageSize + pageSize
    )

  const sortedData = sort(tableData, orderBy, orderType)

  const handleSort = (_, { id = orderBy, type = orderType }: any) => {
    let newOrderType = 'ascending'
    if (orderBy && orderType === 'ascending') {
      newOrderType = 'descending'
    }
    setOrderBy(id)
    setOrderType(newOrderType)
  }

  const totalItems = tableData?.length

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const newTableData = [...tableData]
    const newUnfilteredTableData = [...unFilteredData]
    let newTableDataChecked = [...tableDataChecked]

    const rowIndex = newTableData.findIndex((row) => row.uid === rowId)
    newTableData[rowIndex].rowConfig.selected = checked

    checked
      ? newTableDataChecked.push(newTableData[rowIndex].reportId)
      : (newTableDataChecked = newTableDataChecked.filter(
          (item) => item !== newTableData[rowIndex].reportId
        ))

    newUnfilteredTableData.map((row) => (row.rowConfig.selected = false))
    newUnfilteredTableData.map((row) => {
      newTableDataChecked.map((item) => {
        row.reportId === item && (row.rowConfig.selected = true)
      })
    })

    setTableDataChecked(newTableDataChecked)
    setTableData(newTableData)
    setUnFilteredData(newUnfilteredTableData)
  }

  function handleSelectAllPageItems(dataArray, checked) {
    let newTableDataChecked = [...tableDataChecked]
    return dataArray.map((row) => {
      checked
        ? newTableDataChecked.push(row.reportId)
        : (newTableDataChecked = newTableDataChecked.filter(
            (item) => item !== row.reportId
          ))

      setTableDataChecked(newTableDataChecked)
      const newRow = row
      newRow.rowConfig.selected = checked
      return newRow
    })
  }

  const handleSelectAll = (event) => {
    setTableData(handleSelectAllPageItems(tableData, event.target.checked))
    setUnFilteredData(
      handleSelectAllPageItems(unFilteredData, event.target.checked)
    )
  }
  const handlePageChange = (page) => setCurrentPage(page)
  const handlePageSizeChange = (event, option) => {
    setPageSize(option.value)
    sessionStorage.setItem(
      sessionStorageKeys.reportsPageSize,
      JSON.stringify(option.value)
    )
  }

  const getPageSelectionState = useCallback(() => {
    const total = pagedData()?.length
    const selected = [...pagedData()].filter(
      (row) => row.rowConfig.selected
    ).length

    switch (selected) {
      case 0:
        return 'none'
      case total:
        return 'all'
      default:
        return 'indeterminated'
    }
  }, [pagedData])

  const handleFooterCancel = () => {
    let newTableDataChecked = [...tableDataChecked]
    const updatedTableData = tableData.map((data) => {
      newTableDataChecked = newTableDataChecked.filter(
        (item) => item !== data.reportId
      )
      setTableDataChecked(newTableDataChecked)
      data.rowConfig.selected = false
      return data
    })
    newTableDataChecked.length = 0
    const updatedUnfilteredTableData = unFilteredData.map((data) => {
      data.rowConfig.selected = false
      return data
    })
    setUnFilteredData(updatedUnfilteredTableData)
    setTableDataChecked(newTableDataChecked)
    setTableData(updatedTableData)
  }

  const handleOnColumnReorder = (newOrder) => {
    setOrder(newOrder)
    sessionStorage.setItem(
      sessionStorageKeys.reportsListColumns,
      JSON.stringify(newOrder)
    )
  }

  const deletionFiltering = async (selectedData, data) => {
    let nonSelectedArray = [...data]
    for (let i = 0; i < selectedData.length; i++) {
      const responseDeletion = await deleteReports(
        selectedData[i].reportId,
        stack
      )
      const filteredArray = nonSelectedArray.filter(
        (item1) => item1.reportId !== selectedData[i].reportId
      )
      nonSelectedArray = [...filteredArray]
      if (responseDeletion !== 'success') break
    }
    return nonSelectedArray
  }

  const handleDeleteMultiple = async (selectedData, data) => {
    setLoading(true)
    await deletionFiltering(selectedData, reportResponse).then((response) => {
      if (reportResponse.length - selectedData.length === response.length) {
        setTimeout(() => {
          addToast({
            id: 'Multi-deletePolicyToast',
            type: 'positive',
            text: t(
              'ecp-global-reports.toastNotification.deletionSuccessText',
              {
                deletedNumber: selectedData.length,
                defaultValue: `Deleting ${selectedData.length} reports completed successfully.`
              }
            ),
            timeout: 3
          })
        }, 2000) // Delay of 2 seconds
        if (
          // !(compareData.length - selectedData.length === response.length) &&
          response.length === 0
        ) {
          setCurrentPage(1)
        }
      } else {
        setTimeout(() => {
          addToast({
            id: 'Multi-deletePolicyToast-failure',
            type: 'negative',
            action: (
              <Button
                small
                appearance="ghost"
                onClick={() =>
                  handleDeleteMultiple(numberOfSelectedItems, sortedData)
                }
              >
                {t('ecp-global-reports.button.retry', 'Retry')}
              </Button>
            ),
            text: t(
              'ecp-global-reports.toastNotification.deletionFailuretext',
              {
                deletedNumber: selectedData.length,
                defaultValue: `Failed to delete ${selectedData.length} reports.`
              }
            ),
            timeout: 3
          })
        }, 2000) // Delay of 2 seconds
      }
    })
    await reloadReportList()
  }

  useEffect(() => {
    setNumberOfSelectedItems(
      tableData.filter((data) => data.rowConfig.selected === true)
    )
    setSortEnable(tableData.length !== 0)
  }, [tableData])

  useEffect(() => {
    sessionStorage.getItem(sessionStorageKeys.reportsListColumns) ||
      sessionStorage.setItem(
        sessionStorageKeys.reportsListColumns,
        JSON.stringify(reportTableOrder)
      )
  }, [])

  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const callback = () => setWindowSize(window.innerWidth)

    useEffect(() => {
      window.addEventListener('resize', callback)
      return () => window.removeEventListener('resize', callback)
    }, [])

    return windowSize
  }

  const resolution = useWindowSize()
  const isMobileView = resolution <= 600

  return (
    <ThemeProvider density="high">
      <Wrapper.ReportListTable
        key="report-list-wrapper"
        withPadding={isSearchOpen}
        isMobileView={isMobileView}
      >
        <>
          <DeleteModal
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            onDelete={() => {
              handleDeleteMultiple(numberOfSelectedItems, sortedData)
              handleFooterCancel()
            }}
          />
          {!errorFetching ? (
            <Table
              key="report-list-table"
              customNoItems={
                <Wrapper.RetryCardContainer>
                  <Wrapper.BorderTopCss></Wrapper.BorderTopCss>
                  <Wrapper.RetryCardIcon>
                    <IconWarningAlt
                      size={24}
                      id="unknown-icon"
                      data-testid="unknown-icon"
                    />
                  </Wrapper.RetryCardIcon>
                  <Wrapper.RetryCardContent
                    id="no-results-err"
                    data-testid="no-results-err"
                  >
                    {searchText.length > 0 || reportResponse.length > 0
                      ? t(
                          'ecp-global-reports.tableNoData.noResultsFound',
                          'No Results Found'
                        )
                      : t(
                          'ecp-global-reports.tableNoData.noReportFound',
                          'No Reports Found'
                        )}
                  </Wrapper.RetryCardContent>
                  <span id="empty-err-message" data-testid="empty-err-message">
                    {searchText.length > 0 || reportResponse.length > 0
                      ? t(
                          'ecp-global-reports.tableNoData.noDataOnSearch',
                          'Try selecting other filters or adjusting your search keyword.'
                        )
                      : t(
                          'ecp-global-reports.tableNoData.noDataOnStart',
                          'Generate a report using the generate button.'
                        )}
                  </span>
                </Wrapper.RetryCardContainer>
              }
              data-testid="reports_Reportlist_Table"
              columns={reportsListColumns(tableData)}
              columnReorder={true}
              onColumnReorder={handleOnColumnReorder}
              actionArea={
                READ_SCOPE && (
                  <ReportListSubHeader
                    setSearchText={setSearchText}
                    searchText={searchText}
                    handleSearch={handleSearch}
                    loading={loading}
                    setCurrentPage={setCurrentPage}
                    disableSearchFilter={disableSearchFilter}
                    setIsSearchOpen={setIsSearchOpen}
                    isMobileView={isMobileView}
                  />
                )
              }
              data={sortedData}
              i18n={Tablei18nText(t)}
              loadingDataLength={3}
              onSelect={handleRowSelect}
              onSelectAllPageItems={handleSelectAll}
              pagination={{
                currentPage: tableData.length > 0 ? currentPage : 0,
                onPageChange: handlePageChange,
                onPageSizeChange: handlePageSizeChange,
                pageSize: tableData.length > 0 ? pageSize : 1,
                pageSizeOptions: [
                  { value: 5 },
                  { value: 25 },
                  { value: 50 },
                  { value: 100 },
                  { value: 500 }
                ],
                totalItems: tableData.length > 0 ? totalItems : 0,
                i18n: TablePaginationText(t, currentPage, totalItems)
              }}
              onSort={handleSort}
              preferences={{
                // ...(sortEnable && {
                sortBy: {
                  id: orderBy || 'generatedTime',
                  type: (orderType as SortTypes) || 'descending'
                },
                // }),
                width: [
                  { columnId: 'reportName', width: 300 },
                  { columnId: 'category', width: 150 },
                  { columnId: 'generatedTime', width: 200 },
                  { columnId: 'status', width: 150 },
                  { columnId: 'reportType', width: 300 }
                ],
                defaultOrder: reportTableOrder,
                order
              }}
              {...((WRITE_SCOPE || DELETE_SCOPE) && {
                rowSelector: 'multiSelection'
              })}
              rowSelectAllState={getPageSelectionState()}
              loading={loading}
            />
          ) : (
            <Table
              customNoItems={
                <span>
                  <Wrapper.RetryCardContainer>
                    <Wrapper.BorderTopCss></Wrapper.BorderTopCss>
                    <Wrapper.RetryCardIcon>
                      <IconWarningAlt
                        size={24}
                        id="unknown-icon"
                        data-testid="unknown-icon"
                      />
                    </Wrapper.RetryCardIcon>
                    <Wrapper.RetryCardContent
                      id="unable-to-load-err"
                      data-testid="unable-to-load-err"
                    >
                      {t(
                        'ecp-global-reports.tableNoData.apiFailure',
                        'Unable to Load Data'
                      )}
                    </Wrapper.RetryCardContent>
                    <Wrapper.RetryCardButton
                      onClick={handleReloadPage}
                      id="unable-to-load-retry-btn"
                      data-testid="unable-to-load-retry-btn"
                    >
                      {t('ecp-global-reports.button.retry', 'Retry')}
                    </Wrapper.RetryCardButton>
                  </Wrapper.RetryCardContainer>
                </span>
              }
              actionArea={
                READ_SCOPE && (
                  <ReportListSubHeader
                    setSearchText={setSearchText}
                    searchText={searchText}
                    handleSearch={handleSearch}
                    loading={loading}
                    setCurrentPage={setCurrentPage}
                    errorDisabled={true}
                    disableSearchFilter={disableSearchFilter}
                    setIsSearchOpen={setIsSearchOpen}
                    isMobileView={isMobileView}
                  />
                )
              }
              data-testid="reports_Reportlist_Table"
              columns={reportsListColumns(tableData)}
              columnReorder={true}
              onColumnReorder={handleOnColumnReorder}
              data={[]}
              i18n={Tablei18nText(t)}
              loadingDataLength={3}
              onSelect={handleRowSelect}
              onSelectAllPageItems={handleSelectAll}
              pagination={{
                currentPage: currentPage,
                onPageChange: handlePageChange,
                onPageSizeChange: handlePageSizeChange,
                pageSize,
                pageSizeOptions: [
                  { value: 5 },
                  { value: 25 },
                  { value: 50 },
                  { value: 100 },
                  { value: 500 }
                ],
                totalItems,
                popoverPlacement: 'top-start'
              }}
              onSort={handleSort}
              preferences={{
                ...(sortEnable && {
                  sortBy: {
                    id: 'generatedTime',
                    type: 'descending'
                  }
                }),
                defaultOrder: [
                  'reportName',
                  'category',
                  'reportType',
                  'generatedTime',
                  'status'
                ],
                width: [
                  { columnId: 'reportName', width: 300 },
                  { columnId: 'category', width: 150 },
                  { columnId: 'generatedTime', width: 180 },
                  { columnId: 'status', width: 150 },
                  { columnId: 'reportType', width: 300 }
                ],
                order
              }}
              rowSelector="multiSelection"
              //rowSelectAllState={getPageSelectionState()}
              loading={loading}
            />
          )}

          {numberOfSelectedItems.length > 0 && (
            <ContextualFooter
              reportResponse={reportResponse}
              numberOfSelectedItems={numberOfSelectedItems}
              onCancel={() => handleFooterCancel()}
              onDelete={() => setOpenDeleteModal(true)}
            />
          )}
        </>
      </Wrapper.ReportListTable>
    </ThemeProvider>
  )
}
