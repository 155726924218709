import React from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { IconWarningAlt, Modal } from '@veneer/core'

import * as Wrapper from './styles'
import Footer from './Footer'

export const DeleteModal = (props) => {
  const { open, setOpen, onDelete } = props
  const { t } = useI18n()

  return (
    <div style={{ position: 'relative' }}>
      <Modal
        data-testid="reports_DeleteModal"
        align="start"
        id="modal-id"
        className="small-modal"
        closeOnBlur={false}
        show={open}
        footer={<Footer setOpen={setOpen} onDelete={onDelete} />}
      >
        <Wrapper.ReportsModalHeaderDelete
          data-testid="reports_DeleteModal_Header"
          id="reports_DeleteModal_Header"
        >
          <IconWarningAlt
            filled
            color= "#d06702"
            size={36}
            data-testid="reports_DeleteModal_Icon"
            customStyle={{
              position: 'relative',
              top: '7px',
              marginRight: '8px'
            }}
          ></IconWarningAlt>
          {t('ecp-global-reports.button.delete', 'Delete')}
        </Wrapper.ReportsModalHeaderDelete>
        <p data-testid="reports_DeleteModal_Subtext">
          {t(
            'ecp-global-reports.toastNotification.deletionConfirmation',
            'Are you sure you want to delete the selected reports?'
          )}
        </p>
      </Modal>
    </div>
  )
}
